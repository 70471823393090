const APIs = {
  index: {
    authenticate: '/authenticate/',
    googleAuth: '/googleAuth/',
    signInAsGuest: '/signInAsGuest/',
    logout: '/logout/'
  },
  dashboard: {
    root: '/dashboard/'
  },
  appSetting: {
    root: '/appSetting/'
  },
  contactUs: {
    root: '/contactUs/'
  },
  donation: {
    root: '/donation/'
  },
  donor: {
    root: '/donor/'
  },
  language: {
    root: '/language/'
  },
  resource: {
    root: '/resource/'
  },
  role: {
    root: '/role/'
  },
  status: {
    root: '/status/'
  },
  translate: {
    root: '/translate/'
  },
  translation: {
    root: '/translation/',
    createByWord: '/translation/createByWord/',
    updateByDetails: '/translation/updateByDetails/'
  },
  user: {
    root: '/user/',
    refreshToken: '/user/refreshToken'
  },
  word: {
    root: '/word/'
  },
  wordType: {
    root: '/wordType/'
  },
  starredTranslation: {
    root: '/starredTranslation/'
  }
};
export default APIs;
