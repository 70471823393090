export const REFRESH_TOKEN_BEFORE = 60 * 6; // in minutes
export const GUEST_USER_ID = 3; // in minutes

export const LANGUAGES = [
  {
    id: 1,
    name: 'Indonesia',
    code: 'id-ID'
  },
  {
    id: 2,
    name: 'Ngoko',
    code: 'jv-NG'
  },
  {
    id: 3,
    name: 'Krama',
    code: 'jv-KR'
  },
  {
    id: 4,
    name: 'Krama Inggil',
    code: 'jv-KI'
  }
];

export const STATUSES = [
  {
    id: 1,
    name: 'Active',
    type: 'success'
  },
  {
    id: 2,
    name: 'Inactive',
    type: 'danger'
  },
  {
    id: 3,
    name: 'Pending',
    type: 'warning'
  },
  {
    id: 4,
    name: 'Review',
    type: 'info'
  },
  {
    id: 5,
    name: 'Blocked',
    type: 'secondary'
  },
  {
    id: 6,
    name: 'Suggested',
    type: 'info'
  },
  {
    id: 7,
    name: 'Deleted',
    type: 'danger'
  }
];
