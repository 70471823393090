//app
export const SET_ASIDE_TAB_OPEN = 'SET_ASIDE_TAB_OPEN';
export const TOGGLE_SIGNIN_MODAL = 'TOGGLE_SIGNIN_MODAL';

// general
export const GET_MESSAGES = 'GET_MESSAGES';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const CLEAR_ALL_MESSAGES = 'CLEAR_ALL_MESSAGES';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';

// Dashboard
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';

// Word
export const GET_SUGGESTION_WORD = 'GET_SUGGESTION_WORD';

// Language
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const SELECT_FROM_LANGUAGE = 'SELECT_FROM_LANGUAGE';
export const SELECT_TO_LANGUAGE = 'SELECT_TO_LANGUAGE';

// Status
export const GET_STATUSES = 'GET_STATUSES';

// Translate
export const TRANSLATE = 'TRANSLATE';
export const SELECT_OPTION_TRANSLATE = 'SELECT_OPTION_TRANSLATE';
export const GET_WORD_DICTIONARY_DETAILS = 'GET_WORD_DICTIONARY_DETAILS';

// Starred Translation
export const GET_ACTIVE_STARRED_TRANSLATION = 'GET_ACTIVE_STARRED_TRANSLATION';
